import { useMemo } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';

export type UseReceiptImagesType = (
  receiptImageIds: string[] | undefined
) => UseQueryResult<
  | {
      [p: string]: string | undefined;
    }
  | undefined,
  unknown
>;

interface UseUrlsToImagesProps {
  receiptImageIds: string[] | undefined;
  useReceiptImages: UseReceiptImagesType;
}

export const useUrlsToImages = ({
  receiptImageIds,
  useReceiptImages,
}: UseUrlsToImagesProps): {
  ImageUrlsLoading: boolean;
  receiptImages: string[] | undefined;
} => {
  const {
    data: receiptImagesObject,
    isPending: ImageUrlsPending,
    fetchStatus,
  } = useReceiptImages(receiptImageIds);

  const ImageUrlsLoading = fetchStatus !== 'idle' && ImageUrlsPending;

  const receiptImages: string[] | undefined = useMemo(() => {
    if (receiptImagesObject) {
      return Object.values(receiptImagesObject)
        .filter((item) => item !== undefined)
        .map((item) => item?.toString() ?? '');
    }
    return undefined;
  }, [receiptImagesObject]);

  return {
    ImageUrlsLoading,
    receiptImages,
  };
};
