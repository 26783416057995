import { ResidentWalletMode } from '@pflegenavi/shared/api';

export const RESIDENT_WALLET = 'resident-wallet';

export interface Balance {
  amount: number;
  threshold_due_date: Date | null;
  negative_due_date: Date | null;
}

export function getActiveBalanceId(residentPhoenix?: {
  wallet_mode: ResidentWalletMode;
  family_members: Array<{ primary_contact: boolean; user_id: string }>;
}): string | undefined {
  return residentPhoenix
    ? residentPhoenix.wallet_mode === ResidentWalletMode.resident
      ? RESIDENT_WALLET
      : residentPhoenix.family_members.find(
          (familyMember) => familyMember.primary_contact
        )?.user_id
    : undefined;
}

export function getSelectedBalance(
  residentData?: {
    resident: {
      balance: Balance;
    };
    resident_family_member_balances: Array<
      Balance & { family_member_id: string }
    >;
  },
  selectedBalanceId?: string | null
): Balance | undefined {
  if (!selectedBalanceId || !residentData) {
    return undefined;
  }
  return selectedBalanceId === RESIDENT_WALLET
    ? residentData.resident.balance
    : residentData.resident_family_member_balances.find(
        (balance) => balance.family_member_id === selectedBalanceId
      );
}
