import { useEffect, useMemo, useRef } from 'react';
import type {
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import type { Api } from '../makeApiProvider';
import type { PaginatedResultSubset } from '@pflegenavi/shared/utils';

export const useSuspenseApiQuery = <A extends Api | undefined, T, U>(
  useApi: () => A,
  key: QueryKey,
  callback: (api: A) => Promise<U>,
  options?: Omit<UseSuspenseQueryOptions<U, unknown, T>, 'queryKey' | 'queryFn'>
): UseSuspenseQueryResult<T, unknown> => {
  const api = useApi();

  const result = useSuspenseQuery({
    queryKey: key,
    queryFn: () => {
      if (api?.authContext.getTokenImmediate() === undefined) {
        throw new Error('No token available');
      }
      return callback(api);
    },
    ...options,
  });

  const { refetch } = result;

  const userId = useMemo(
    () => api?.authContext.user?.userId,
    [api?.authContext]
  );

  const isIdleRef = useRef(result.fetchStatus === 'idle');

  useEffect(() => {
    if (userId !== undefined && isIdleRef.current) {
      refetch();
    }
  }, [userId, refetch]);

  return result;
};

export const useApiQuery = <A extends Api | undefined, T, U>(
  useApi: () => A,
  key: QueryKey,
  callback: (api: A) => Promise<U>,
  options?: Omit<UseQueryOptions<U, unknown, T>, 'queryKey' | 'queryFn'>
): UseQueryResult<T, unknown> => {
  const api = useApi();

  const enabled =
    (options?.enabled ?? true) &&
    api?.authContext.getTokenImmediate() !== undefined;

  const result = useQuery({
    queryKey: key,
    queryFn: () => {
      if (
        options?.enabled === false ||
        api?.authContext.getTokenImmediate() === undefined
      ) {
        return undefined as unknown as U;
      }
      return callback(api);
    },
    ...options,
    enabled,
  });

  const { refetch } = result;

  const userId = useMemo(
    () => api?.authContext.user?.userId,
    [api?.authContext]
  );

  const isIdleRef = useRef(result.isLoading);

  useEffect(() => {
    const transitionedFromIdle = isIdleRef.current && !result.isLoading;
    if (userId !== undefined && transitionedFromIdle) {
      refetch();
    }
  }, [userId, result.isLoading, refetch]);

  useEffect(() => {
    if (isIdleRef.current !== result.isLoading) {
      isIdleRef.current = result.isLoading;
    }
  }, [result.isLoading]);

  return result;
};

/**
 * Same as useApiQuery, but allows to refetch when enabled is false (like useQuery default behaviour)
 */
export const useApiQueryNoRefetchOverride = <A extends Api | undefined, T, U>(
  useApi: () => A,
  key: QueryKey,
  callback: (api: A) => Promise<U>,
  options?: Omit<UseQueryOptions<U, unknown, T>, 'queryKey' | 'queryFn'>
): UseQueryResult<T, unknown> => {
  const api = useApi();

  const enabled =
    api?.authContext.getTokenImmediate() !== undefined
      ? options?.enabled
      : false;

  const result = useQuery({
    queryKey: key,
    queryFn: () => {
      if (api?.authContext.getTokenImmediate() === undefined) {
        return undefined as unknown as U;
      }
      return callback(api);
    },
    ...options,
    enabled,
  });

  const { refetch } = result;

  const userId = useMemo(
    () => api?.authContext.user?.userId,
    [api?.authContext]
  );

  const isIdleRef = useRef(result.isLoading);

  useEffect(() => {
    const transitionedFromIdle = isIdleRef.current && !result.isLoading;
    if (userId !== undefined && transitionedFromIdle) {
      refetch();
    }
  }, [userId, result.isLoading, refetch]);

  useEffect(() => {
    if (isIdleRef.current !== result.isLoading) {
      isIdleRef.current = result.isLoading;
    }
  }, [result.isLoading]);

  return result;
};

export const usePrefetchApiQuery = <A extends Api, T>(
  useApi: () => A,
  key: QueryKey,
  callback: (api: A) => Promise<T>,
  enabled?: boolean
): void => {
  const api = useApi();
  const client = useQueryClient();
  useEffect(() => {
    (async () => {
      if (enabled && api.authContext.user?.userId !== undefined) {
        await client.prefetchQuery({
          queryKey: key,
          queryFn: () => callback(api),
        });
      }
    })();
  }, [api, api.authContext.user?.userId, callback, client, enabled, key]);
};

export const useApiInfiniteQuery = <
  A extends Api | undefined,
  T,
  U extends PaginatedResultSubset<any>
>(
  useApi: () => A,
  key: QueryKey,
  callback: (api: A, page: number) => Promise<U>,
  options?: Omit<
    UseInfiniteQueryOptions<U, unknown, T, U, QueryKey, number>,
    | 'queryKey'
    | 'queryFn'
    | 'getNextPageParam'
    | 'getPreviousPageParam'
    | 'initialPageParam'
  >
): UseInfiniteQueryResult<T, unknown> => {
  const api = useApi();

  const enabled =
    (options?.enabled ?? true) &&
    api?.authContext.getTokenImmediate() !== undefined;

  const result = useInfiniteQuery({
    queryKey: key,
    queryFn: ({ pageParam }) => {
      if (
        options?.enabled === false ||
        api?.authContext.getTokenImmediate() === undefined
      ) {
        return undefined as unknown as U;
      }
      return callback(api, pageParam);
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.currentPage === lastPage.meta.totalPages - 1) {
        return undefined;
      }
      return lastPage.meta.currentPage + 1;
    },
    getPreviousPageParam: (firstPage) => {
      if (firstPage.meta.currentPage === 0) {
        return undefined;
      }
      return firstPage.meta.currentPage - 1;
    },
    ...options,
    enabled,
  });

  const { refetch } = result;

  const userId = useMemo(
    () => api?.authContext.user?.userId,
    [api?.authContext]
  );

  useEffect(() => {
    if (userId !== undefined && !result.isLoading) {
      refetch();
    }
  }, [userId, result.isLoading, refetch]);

  return result;
};
